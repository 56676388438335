<template>
  <div>
    <div class="DivBox">
      <p class="PTitle">构建体系数据链</p>
    </div>
    <div class="DivBox">
      <p class="PTitle LeftBor">服务介绍：</p>
      <p class="SmallP">首先，用一个逻辑图表达表单的关联，形成信息链，例如：</p>
      <img
        @click="openWindow"
        src="@/assets/FirstPage-ProductSon/18.png"
        class="SonImgW"
      />
      <br />
      <br />
      <br />
      <div class="ImgP">
        <p class="SmallP" style="width: auto">表1 新产品设计和开发任务书</p>
        <img
          @click="openWindow"
          src="@/assets/FirstPage-ProductSon/19-1.png"
          class="SonImgW"
        />
      </div>
      <div class="ImgP">
        <p class="SmallP" style="width: auto">表2 试验大纲</p>
        <img
          @click="openWindow"
          src="@/assets/FirstPage-ProductSon/19.png"
          class="SonImgW"
        />
      </div>
      <div class="ImgP">
        <p class="SmallP" style="width: auto">表3 可靠性试验记录（F10301）</p>
        <img
          @click="openWindow"
          src="@/assets/FirstPage-ProductSon/19-2.png"
          class="SonImgW"
        />
      </div>
      <div class="ImgP">
        <p class="SmallP" style="width: auto">
          表4 新产品可靠性试验报告（F10303）
        </p>
        <img
          @click="openWindow"
          src="@/assets/FirstPage-ProductSon/20.png"
          class="SonImgW"
        />
      </div>
      <p class="SmallP">
        其实就是表格相关栏目的接口。有了信息链的基础，就能做到资料的同步变更。数据逻辑是由表单之间的连接、表单栏目的连接组成的。从以上的《新产品设计和开发任务书》、《试验大纲》、《可靠性试验记录》到《新产品可靠性试验报告》就可以看到数据链的设计过程。这里只不过列举了一个从产品性能指标到试验数据、试验报告的数据链栏目设计的例子，其实还有设计成本、产品尺寸等的数据链设计。在数字体系中，70%以上的作业都与数据有关，与此相关的表单的数据栏目就有很多，数据链的设计就显得尤为关键。
      </p>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    openWindow() {
      window.open("https://www.yuntixi.cn", "_blank");
    },
  },
};
</script>

<style>
@import "../../../../assets/style/FirstProductSon.css";
</style>